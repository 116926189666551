<template>
  <div>
    <SelectedItemsBar
      v-if="showSelectedItemBar"
      :selectedItems="selectedNodes"
      ref="selectBar"
      @clear-selection="clearSelection"
      @copy-success="clearSelection"
      @move-success="clearSelection"
      :currentNode="$route.params.parentId"
    />

    <DefaultModal
      :title="$t('move_confirm_title')"
      ref="moveModal"
      @submit="callMoveMethod"
      @modal-closed="resetConfirmModal()"
      submit-button-text="move"
      :loading="modalLoading"
      :saveButtonDisabled="modalSaveButtonDisabled"
      :alert="modalAlert"
    >
      <template v-slot:content>
        <v-col class="text-body-1">
          <v-row>
           <p class="pre-line"><span class="font-weight-bold">From:</span> {{destination.from}}</p>
          </v-row>
          <v-row>
            <p><span class="font-weight-bold">To:</span> {{destination.to}}</p>
          </v-row>
          <v-row v-if="modalAlert" class="mt-4">
            <v-alert
              dense
              outlined
              :type="modalAlert.type"
            >
              {{modalAlert.message}}
            </v-alert>
          </v-row>
        </v-col>
      </template>
    </DefaultModal>

    <v-row>
      <v-btn
        @click="onChangeOrderBy('name')"
        class="text-none"
        small
        text
      >
        {{ $t('name') }}
        <v-icon
          small
        >{{ getOrderByIcon('name') }}</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        @click="onChangeOrderBy('updated_at')"
        class="text-none"
        small
        text
      >
        {{ $t('last_modification') }}
        <v-icon
          small
        >{{ getOrderByIcon('updated_at') }}</v-icon>
      </v-btn>
    </v-row>

    <v-row v-if="folders.length > 0">
      <v-col
        class="py-2 px-2"
        v-for="item in folders"
        :key="item.node_id"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <div
          :draggable="true"
          @drop="onDrop"
          @dragstart="dragStart(item)"
          @dragover="dragOver($event, item)"
          @dragenter="dragEnter($event, item)"
          @dragleave="dragLeave()"
          @dragend="dragEnd()"
          :class="{'drag-over': droppedNode != null && item.node_id == droppedNode.node_id && dragInternal}"
        >
          <FolderCard
            dense
            :data="item"
            @click-folder="$emit('enter-folder', item)"
            @toggle-selection="toggleSelection"
            ref="folderCardRef"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="files.length > 0">
      <v-col
        v-for="item in files"
        :key="item.node_id"
        class="d-flex child-flex py-2 px-2"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <div
          :draggable="true"
          @dragstart="dragStart(item)"
        >
          <FileCard
            :data="item"
            @toggle-selection="toggleSelection"
            ref="fileCardRef"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
  .drag-over {
    border: 2px dashed var(--v-primary-base);
    border-radius: 4px;
    background-color: var(--v-primary-lighten5);
  }
</style>

<script>

import _ from 'lodash'
import { mapActions } from 'vuex'
import SelectedItemsBar from '@/components/content/SelectedItemsBar.vue'
import FolderCard from '@/components/content/FolderCard.vue'
import FileCard from '@/components/content/FileCard.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: "NavigationGridView",

  components: {
    DefaultModal,
    FolderCard,
    FileCard,
    SelectedItemsBar,
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    entries: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      orderBy: {
        name: null,
        updated_at: null,
      },
      selectedNodes: [],
      droppedNode: null,
      dragInternal: false,
      sourceNodeList: [],
      destinationNode: null,
      destination: {},
      modalLoading: true,
      modalSaveButtonDisabled: true,
      modalAlert: null,
    }
  },

  computed: {
    folders() {
      return this.orderItems(this.entries.filter(e => e.type == "folder" && !  /^\..*/.test(e.name)))
    },

    files() {
      return this.orderItems(this.entries.filter(e => e.type == "file" && ! /^\..*/.test(e.name)))
    },

    showSelectedItemBar() {
      return this.selectedNodes.length > 0
    }
  },

  methods: {

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.MOVE,
      ContentActions.MOVE_ENTRIES
    ]),

    ...mapActions('content/entry', [
      EntryActions.CHECK_COPY_MOVE_POSSIBILITY,
    ]),

    orderItems(items) {
      const notNullFilters = _.pickBy(this.orderBy)

      if (_.isEmpty(notNullFilters)) {
        return items
      }

      return _.orderBy(items, _.keys(notNullFilters), _.values(notNullFilters))
    },

    onChangeOrderBy(key) {
      const currentValue = this.orderBy[key]
      let newValue = null

      switch (currentValue) {
        case null:
          newValue = 'asc'
          break
        case 'asc':
          newValue = 'desc'
          break
        case 'desc':
          newValue = null
          break
      }

      this.orderBy[key] = newValue
    },

    getOrderByIcon(key) {
      const value = this.orderBy[key]

      switch (value) {
        case 'asc':
          return 'mdi-arrow-up'
        case 'desc':
          return 'mdi-arrow-down'
        default:
          return ''
      }
    },

    toggleSelection(nodeId) {
      const selectedNode = this.entries.find(e => e.node_id === nodeId);

      if (this.selectedNodes.some(node => node.node_id === nodeId)) {
        this.selectedNodes = this.selectedNodes.filter(e => e.node_id !== nodeId);
      } else {
        this.selectedNodes.push(selectedNode);
      }
    },

    clearSelection(){
      this.selectedNodes = [];

      this.$refs.fileCardRef?.forEach(fileCard => {
        fileCard.isSelected = false;
      })

      this.$refs.folderCardRef?.forEach(folderCard => {
        folderCard.checked = false;
      })
    },

    dragStart(item) {
      parent.dragInternal = true
      this.dragInternal = true
      parent.droppedNode = null
      parent.draggedList = [item]
      this.selectedNodes.forEach(node => {
        if (node.node_id == item.node_id) {
          parent.draggedList = this.selectedNodes
          return true
        }
      })
      return true
    },

    dragOver(event, item) {
      this.dragInternal = parent.dragInternal;
      if (this.dragInternal && item.type == "folder") {
        parent.droppedNode = item
        this.droppedNode = item
        event.preventDefault()
      } else {
        parent.droppedNode = null
        this.droppedNode = null
      }
    },

    dragEnter(event, item) {
      this.dragOver(event, item)
    },

    dragEnd() {
      parent.dragInternal = false
      this.dragInternal = false
      parent.droppedNode = null
      this.droppedNode = null
    },

    dragLeave() {
      parent.droppedNode = null
      this.droppedNode = null
    },

    onDrop() {
      var internal = parent.dragInternal
      parent.dragInternal = false
      this.dropFileDragOver = false

      if (parent.droppedNode.node_id > 0 && internal) {
        var valid = true;
        parent.draggedList.forEach(item => {
          if (item.node_id == parent.droppedNode.node_id) {
            valid = false
          }
        })
        if (valid) {
          this.sourceNodeList = parent.draggedList
          this.destinationNode = parent.droppedNode
          this.confirmModal()
        }
        return
      }
    },

    confirmModal() {
      const sourceNodeList = this.sourceNodeList
      const destinationNode = this.destinationNode

      this.destination.from = '\n' + sourceNodeList.map(i => i.entry_fs_id).join(',\n');

      this.destination.to = destinationNode.node_parent === null ? `/${destinationNode.name}` : destinationNode.entry_fs_id;

      this.resetConfirmModal()
      this.$refs.moveModal.openModal()

      let nodesIds = sourceNodeList.map(item => item.node_id)

      const data = {"destination_id": destinationNode.node_id, "action_key": "move", "nodes_ids": nodesIds}

      var canContinue = true;

      this[EntryActions.CHECK_COPY_MOVE_POSSIBILITY](data)
        .then(response => {
          this.modalLoading = false;

          response.forEach(res => {
            if (res.node_name_already_exists) {
              canContinue = false;
              this.modalAlert = { type: "error", message: this.$t('move_confirm_alert') }
            }
          })
        })
        .catch(() => this.loading = false)
        .finally(() => this.modalSaveButtonDisabled = !canContinue)
    },

    resetConfirmModal() {
      this.modalLoading = true;
      this.modalSaveButtonDisabled = true;
      this.modalAlert = null;
      this.dragEnd();
    },

    callMoveMethod() {
      this.$refs.moveModal.closeModal()

      let nodesIds = this.sourceNodeList.map(item => item.node_id)
      let params = {'entries': nodesIds, 'destination_id': this.destinationNode.node_id, 'name': name}

      this[ContentActions.MOVE_ENTRIES](params)
        .then(() => {
          this.selectedNodes = []
        })
        .catch((error) => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t(`move_failed`))
          this.$emit(`move-error`, error)
        })
    }
  }
}

</script>
